/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
  useMediaQuery,
  Container,
  CircularProgress,
  Grid,
  Box,
  Stack,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../../constants/theme';
import { DigitalToolkitListCard } from '../cards/DigitalToolkitListCard';
import { DigitalToolkitCardSource, DigitalToolkitsSource } from '../../utils/enums';
import {
  useTypedDispatch,
  useTypedSelector,
} from '../../hooks/TypedReduxHooks';
import {
  ChangePublishStateRequest,
  DeleteRequest,
  DigitalToolkitGroupItem,
  GetRequest,
  ListRequest,
  ReOrderRequest,
  SearchRequest,
  LogAction,
  LogActionType,
} from '../../service/digitalToolkitService';
import { ListDivider } from '../../components-molecules/Divider';
import { useNavigate } from 'react-router-dom';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { PageHeader } from '../../components-molecules/titlebars/PageHeader';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';
import { NoItems } from '../../components-molecules/NoItems';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { TabPanel } from '@mui/lab';
import { ListingCard } from '../cards/listing/ListingCard';
import { ButtonContainedPrimary, ButtonOutlinedPrimary } from '../../components-atoms/ButtonComponents';
import ShareRestrictedContent from '../modals/ShareRestrictedContent';

const pageSize = 10;
interface IProps {
  service?: any;
  source: DigitalToolkitsSource;
}

export function DigitalToolkitsList({ source, service }: IProps): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const [digitalToolkits, setDigitalToolkits] = useState<any[]>([]);
  const [digitalToolkitGroupItems, setDigitalToolkitGroupItems] = useState<
    DigitalToolkitGroupItem[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [showRestrictedPopup, setShowRestrictedPopup] = useState<string>();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const user = useTypedSelector((state) => state.userReducer.data);
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;

  const loadMore = () => {
    if (loading) return;
    if (token) {
      source === DigitalToolkitsSource.Client
        ? getToolkits(token, page + 1, pageSize, digitalToolkits)
        : getToolkitGroups(token, page + 1, pageSize, digitalToolkitGroupItems);
      setPage(page + 1);
    }
  };

  const getToolkits = useCallback(
    (token: string, page = 0, pageSize = 10, prevData: any[] = []) => {
      if (token) {
        setLoading(true);
        ListRequest(
          token,
          {
            Size: pageSize,
            Page: page,
            ServiceId: service?.Id
          },
          (response) => {
            if (response) {
              setTotal(response.Result.Count);
              setDigitalToolkits(prevData.concat(response.Result.Result));
              setLoading(false);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      }
    },
    [service?.Id]
  );

  const getToolkitGroups = useCallback(
    (token: string, page = 0, pageSize = 10, prevData: any[] = []) => {
      if (token) {
        setLoading(true);
        SearchRequest(
          token,
          {
            Size: pageSize,
            Page: page,
            Query: query,
          },
          (response) => {
            if (response) {
              setTotal(response.Result.Count);
              let temp = [...prevData];
              response.Result.Result.map((m) => {
                const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
                if (tempMatch) {
                  temp[temp.indexOf(tempMatch)].Products = [
                    ...temp[temp.indexOf(tempMatch)].Products,
                    ...m.Products,
                  ];
                } else {
                  temp = [...temp, m];
                }
              });
              setDigitalToolkitGroupItems(temp);
              setLoading(false);
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => { }
        );
      }
    },
    [query]
  );

  const changePublishState = (id: string) => {
    if (token) {
      setLoading(true);
      setDigitalToolkitGroupItems([]);
      ChangePublishStateRequest(
        token,
        {
          Id: id,
        },
        () => {
          setRefresh(!refresh);
          setPage(0);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const onMove = (newData: DigitalToolkit[]) => {
    if (token) {
      const idList = newData.map((m) => ({ Id: m.Id }));
      ReOrderRequest(
        token,
        idList,
        () => {
          return;
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const movebyIdx = (idx: number, step: number) => {
    const tempItems = [...digitalToolkitGroupItems];
    const temp = [
      ...digitalToolkitGroupItems.filter((f) => f.GroupName === 'Published')[0]
        .Products,
    ];
    const tempItem = temp[idx];
    temp[temp.indexOf(tempItem)] = temp[step];
    temp[step] = tempItem;
    tempItems[
      tempItems.indexOf(
        digitalToolkitGroupItems.find(
          (f) => f.GroupName === 'Published'
        ) as DigitalToolkitGroupItem
      )
    ].Products = temp;
    setDigitalToolkitGroupItems([...tempItems]);
    onMove(temp);
  };

  const moveUp = (idx: number) => {
    movebyIdx(idx, idx - 1);
  };

  const moveDown = (idx: number) => {
    movebyIdx(idx, idx + 1);
  };

  const getDigitalToolkit = (id: string) => {
    if (token) {
      GetRequest(
        token,
        id,
        (response) => {
          if (response) {
            dispatch({
              type: 'SET_CURRENT_DIGITALTOOLKIT',
              payload: { currentData: response.Result },
            });
            navigate('/admin/edit-digitaltoolkit');
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const addToolkit = () => {
    navigate('/admin/add-digitaltoolkit');
  };

  const deleteDigitalToolkit = (id: string) => {
    if (token) {
      DeleteRequest(
        token,
        id,
        () => {
          setRefresh(!refresh);
          setPage(0);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const searchOnChange = (text: string) => {
    setQuery(text);
  };

  useEffect(() => {
    if (token)
      source === DigitalToolkitsSource.Client
        ? getToolkits(token)
        : getToolkitGroups(token);
  }, [token, getToolkits, getToolkitGroups, query, refresh, source]);

  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  // Card

  const downloadPdf = (product: any) => {
    const fileUrl = product.FileUrl;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = product.FileUrl.split('/').pop() as string;
        downloadLink.click();
        URL.revokeObjectURL(url);
        LogAction(token, product.Id, LogActionType.download, product.FileUrl);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  function onClick(product: any, _source: DigitalToolkitCardSource) {
    window.open(`/detail/toolkit/${product.Id}`, '_blank');
    LogAction(token, product.Id, LogActionType.view, product.FileUrl);
    navigate(`/detail/toolkit/${product.Id}`, { state: { modalIndex: -1 } });
  }


  function renderButtons(item: any, source: DigitalToolkitCardSource) {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonOutlinedPrimary
          onClick={() => onClick(item, source)}
          sx={{ height: '36px' }}>
          View PDF
        </ButtonOutlinedPrimary>
        <ButtonContainedPrimary
          onClick={() => downloadPdf(item)}
          sx={{ height: '36px' }}>
          Download PDF
        </ButtonContainedPrimary>
      </Stack>
    );
  }

  // Card

  function renderList() {
    if (source === DigitalToolkitsSource.Client) {
      return digitalToolkits.map((item: any, index: number) => (
        <ListingCard
          hideEllipsis
          key={item.Id}
          isFirst={index === 0}
          isLast={index === digitalToolkits.length - 1}
          title={item.Title}
          thumbnailUrl={item.ThumbnailUrl}
          description={item.Description}
          customButtons={() => renderButtons(item, DigitalToolkitCardSource.Listing)} />
      ));
    }

    return digitalToolkitGroupItems.map(
      (groupItem: DigitalToolkitGroupItem) => (
        <ListDivider
          key={groupItem.GroupName}
          division={{
            name: groupItem.GroupName,
            length: groupItem.Products.length,
          }}
          caption={{ singular: 'Digital Toolkit', plural: 'Digital Toolkits' }}
          halfMargin
          noBottomPadding
        >
          <Grid
            container
            item
            xs={12}
            columnSpacing={2}
            rowSpacing={3}
            sx={{ width: '100%', margin: 0 }}
          >
            {groupItem.Products.map((item, index) => (
              <DigitalToolkitListCard
                key={item.Id}
                index={index}
                count={groupItem.Products.length}
                product={item}
                showControls={!isTMorSM}
                onEdit={function (id: string): void {
                  getDigitalToolkit(id);
                }}
                onDelete={function (id: string): void {
                  setSelectedId(id);
                  setModalOpen(true);
                }}
                onPublishChange={function (id: string): void {
                  changePublishState(id);
                }}
                onShare={function (id: string, isRestricted: boolean): void {
                  if (isRestricted) {
                    setShowRestrictedPopup(id);
                    return;
                  }
                  navigate('/admin/digital-toolkit-share/' + id);
                }}
                moveUp={function (index: number): void {
                  moveUp(index);
                }}
                moveDown={function (index: number): void {
                  moveDown(index);
                }}
              />
            ))}
          </Grid>
        </ListDivider>
      )
    );
  }

  const getItemCount = () => {
    let totalItemCount = 0;
    digitalToolkitGroupItems.forEach((group) => {
      totalItemCount += group.Products.length;
    });
    return totalItemCount;
  };

  function renderContent() {
    return (
      <Grid width='100%' px={{ xs: '20px', sm: 4 }}>
        <InfiniteScroll
          style={{ overflowY: 'hidden' }}
          dataLength={
            source === DigitalToolkitsSource.Client
              ? digitalToolkits.length
              : getItemCount()
          } //This is important field to render the next data
          next={() => debounce(() => loadMore(), timeoutDuration)()}
          scrollThreshold={smallDown ? '550px' : '50px'}
          hasMore={
            source === DigitalToolkitsSource.Client
              ? digitalToolkits.length !== total
              : getItemCount() !== total
          }
          loader={
            <Container
              maxWidth="sm"
              sx={{
                position: 'relative',
                marginTop: '50px',
                paddingBottom: '80px',
              }}
            >
              <CircularProgress
                sx={{ position: 'absolute', top: '30%', left: '50%' }}
                size={30}
              />
            </Container>
          }
        >
          <Box>
            {source === DigitalToolkitsSource.Admin ? (
              <>
                <PageHeader
                  title={isTMorSM ? 'Digital Toolkits' : ''}
                  buttonTitle={isTMorSM ? '' : 'Add Toolkit'}
                  activeButtons={true}
                  modalCallback={addToolkit}
                  onClickSearch={() => setSearchOpen(!searchOpen)}
                  noSort
                />
                <SearchbarWithDeboubce
                  isOpen={searchOpen}
                  query={query}
                  placeholder="Search Toolkits"
                  onCancel={() => {
                    setSearchOpen(false);
                    setQuery('');
                  }}
                  onChange={searchOnChange}
                />
                {modalOpen ? (
                  <MiniDialog
                    title="Delete Toolkit"
                    open={modalOpen}
                    disabled={!selectedId}
                    close={() => {
                      setModalOpen(false);
                      setSelectedId(undefined);
                    }}
                    remove={() => {
                      if (selectedId) {
                        deleteDigitalToolkit(selectedId);
                      }
                      setSelectedId(undefined);
                      setModalOpen(false);
                    }}
                  >
                    <Box sx={{ padding: '0 24px' }}>
                      <BodyOneSecondary>
                        {'Are you sure that you want to remove this Toolkit?'}
                      </BodyOneSecondary>
                    </Box>
                  </MiniDialog>
                ) : null}
                {!loading && digitalToolkitGroupItems.length <= 0 ? (
                  <NoItems
                    icon={
                      <BusinessCenterIcon
                        sx={{
                          fontSize: 88,
                          fill: LIGHT_THEME.palette.primary.main,
                        }}
                      />
                    }
                    title="No toolkits yet"
                    description="You haven’t added any toolkits yet. Start by adding the first toolkit."
                  />
                ) : null}
              </>
            ) : null}
          </Box>

          {loading ? (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 320,
              zIndex: 999,
            }}>
              <CircularProgress
                color="primary"
                size={50}
              />
            </Box>
          ) : (
            renderList()
          )}

          <ShareRestrictedContent
            open={Boolean(showRestrictedPopup)}
            onAccept={() => navigate('/admin/digital-toolkit-share/' + showRestrictedPopup)}
            onClose={() => setShowRestrictedPopup(undefined)} />
        </InfiniteScroll>
      </Grid>
    );
  }

  if (source === DigitalToolkitsSource.Admin) {
    return (
      <TabPanel value={'content'} sx={{ padding: 0, width: '100%' }}>
        {renderContent()}
      </TabPanel>
    );
  }

  return renderContent();
}
