import { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Pagination, Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import { LIGHT_THEME } from '../constants/theme';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { BodyOnePrimary, BodyTwoPrimary, CaptionPrimary, HeaderSix } from '../components-atoms/TypographyComponents';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';
import { AnalyticsFilterModel, GetShareAnalytics, ShareAnalyticsRowModel } from '../service/analyticsService';
import { environment } from '../environment';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneIcon from '@mui/icons-material/Done';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CollectionsIcon from '@mui/icons-material/Collections';
import { NoItems } from '../components-molecules/NoItems';

/* eslint-disable @typescript-eslint/naming-convention */

const SORT_TYPES = [
  {
    title: 'Date Ascending',
    value: 'asc'
  },
  {
    title: 'Date Descending',
    value: 'desc'
  }
];

const styles = {
  container: { mt: 4, flex: 1, width: '100%', marginBottom: '20px' },
  doneIcon: { color: LIGHT_THEME.palette.success.dark, fontSize: '0.87rem', mr: 1 },
  closeIcon: { color: LIGHT_THEME.palette.error.dark, fontSize: '0.87rem', mr: 1 },
  dateIcon: { ml: 2, mr: 0.5, fontSize: '1.1rem' },
  chip: { display: 'flex', alignItems: 'center', padding: '4px 12px', height: '22px', backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: 16 },
};

export default function AdminAnalytics(): JSX.Element {
  const user = useTypedSelector((state) => state.userReducer.data);
  const isAdmin = user?.Role === 'Super Admin';
  const [token] = useTypedSelector((state) => [state.userReducer.token]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState<ShareAnalyticsRowModel[]>();

  const [loading, setLoading] = useState(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilter] = useState<ISelectedOptions>({});
  const [filterOptions, setFilterOptions] = useState({});
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    setLoading(true);

    if (token) {
      const requestFilters: any = {};

      for (const key in filters) {
        const nw = key.replaceAll(' ', '');
        requestFilters[nw] = filters[key];
      }

      if (requestFilters['HasOpened']) {
        requestFilters['HasOpened'] = requestFilters['HasOpened'][0];
      }

      if (requestFilters['HasDownloaded']) {
        requestFilters['HasDownloaded'] = requestFilters['HasDownloaded'][0];
      }

      GetShareAnalytics(
        token,
        { Query: query, Page: page, Size: pageSize, OrderBy: sortType, Filters: requestFilters },
        (res) => {
          setTotalCount(res.Count);
          setData(res.Rows);
          handleFilters(res.ApplicableFilters);
        },
        () => { /* */ }
      );
    }
  }, [page, query, sortType, filters, token]);

  const handleFilters = (filters: AnalyticsFilterModel) => {
    const _filterOptions: any = {
      'Date Range': []
    };

    if (filters.AssetTypes) {
      _filterOptions['Asset Type'] = filters.AssetTypes?.map((name) => ({ title: name, value: name }));
    }
    if (filters.SharerNames) {
      _filterOptions['Sharer Name'] = filters.SharerNames?.map((name) => ({ title: name, value: name }));
    }
    if (filters.SharerRoles) {
      _filterOptions['Sharer Role'] = filters.SharerRoles?.map((name) => ({ title: name, value: name }));
    }
    if (filters.RecipientNames) {
      _filterOptions['Recipient Name'] = filters.RecipientNames?.map((name) => ({ title: name, value: name }));
    }
    if (filters.RecipientRoles) {
      _filterOptions['Recipient Role'] = filters.RecipientRoles?.map((name) => ({ title: name, value: name }));
    }
    if (filters.RecipientEmails) {
      _filterOptions['Recipient Email'] = filters.RecipientEmails?.map((name) => ({ title: name, value: name }));
    }
    if (filters.RecipientMerchants) {
      _filterOptions['Recipient Merchant'] = filters.RecipientMerchants?.map((name) => ({ title: name, value: name }));
    }
    _filterOptions['Has Opened'] = [{ title: 'Yes', value: true }, { title: 'No', value: false }];
    _filterOptions['Has Downloaded'] = [{ title: 'Yes', value: true }, { title: 'No', value: false }];

    setFilterOptions(_filterOptions);
    setLoading(false);
  };

  const searchOnChange = (text: string) => {
    setQuery(text);
  };

  const onSortChange = (type: string) => {
    setSortType(type as 'asc' | 'desc');
  };

  const onPageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(value - 1);
  };

  const onFilterSubmit = (selected: ISelectedOptions) => {
    if (JSON.stringify(selected) === JSON.stringify(filters)) return;
    setFilter(selected);
  };

  return (
    <Dashboard justifyContent='flex-start'>
      <PageHeader
        hasFilter
        activeButtons
        showSearch
        title='Shared Assets'
        subtitle={(loading && !totalCount) ? '' : `Showing 1 - ${(page + 1) * pageSize} of ${totalCount}`}
        buttonTitle={isAdmin ? 'Industry Cloud' : undefined}
        buttonIcon={isAdmin ? <OpenInNewIcon /> : undefined}
        sortTypes={SORT_TYPES}
        selectedSortType={sortType}
        handleSortSelect={onSortChange}
        filterOptions={filterOptions}
        onFilterSubmit={onFilterSubmit}
        onClickSearch={() => setSearchOpen(true)}
        modalCallback={() => isAdmin ? window.open(environment.industryCloudUrl, '_blank')?.focus() : undefined}
        isAdmin={true}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Shared Assets"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      <Stack sx={styles.container}>
        <Stack>
          {loading
            ? <Stack width='100%' height='640px' justifyContent='center' alignItems='center'>
              <CircularProgress
                color="primary"
                size={36}
                sx={{ margin: '64px 0' }}
              />
            </Stack>
            : !loading && totalCount === 0
              ? <NoItems
                icon={
                  <CollectionsIcon
                    sx={{
                      fontSize: 88,
                      fill: LIGHT_THEME.palette.primary.main,
                    }}
                  />
                }
                title="No Shared Assets"
                description="No shared assets found. Try sharing assets with your team or changing your search query."
              /> : data?.map((row, idx) => (
                <Box key={idx + row.AssetName + row.DateOfShare + row.SharerName + row.RecipientEmail} sx={{ borderRadius: '20px', border: '1px solid rgba(0, 0, 0, 0.12)', marginBottom: '24px', padding: '24px 20px', borderBottom: data.length === idx + 1 ? '' : '1px solid rgba(0, 0, 0, 0.12)' }}>
                  <Stack direction='row' alignItems='center' gap={1} justifyContent='space-between'>
                    <Stack direction='row' alignItems='center'>
                      <HeaderSix fontSize='1.10rem' sx={{ m: 0 }}>{row.AssetName}</HeaderSix>
                      <HeaderSix fontSize='0.8rem' sx={{ m: 0, ml: 2, mr: 0.5, fontWeight: 'normal' }}>{row.AssetType}</HeaderSix>
                      <DateRangeIcon sx={styles.dateIcon} />
                      <HeaderSix fontSize='0.8rem' sx={{ m: 0, fontWeight: 'normal' }}>{moment(row.DateOfShare).format('MMM DD, YYYY')}</HeaderSix>
                    </Stack>
                    <Stack direction='row' gap={1}>
                      <Box sx={styles.chip}>
                        {row.HasOpened
                          ? <DoneIcon sx={styles.doneIcon} />
                          : <CloseIcon sx={styles.closeIcon} />}
                        <BodyTwoPrimary>Opened</BodyTwoPrimary>
                      </Box>
                      <Box sx={styles.chip}>
                        {row.HasDownloaded
                          ? <DoneIcon sx={styles.doneIcon} />
                          : <CloseIcon sx={styles.closeIcon} />}
                        <BodyTwoPrimary>Downloaded</BodyTwoPrimary>
                      </Box>
                    </Stack>
                  </Stack>
                  <Grid container columnSpacing={1} mt={1}>
                    <Grid item xs={4}>
                      <CaptionPrimary>Sharer</CaptionPrimary>
                      <Stack direction='row' alignItems='center' gap={0.5}>
                        <BodyOnePrimary>{row.SharerName} </BodyOnePrimary>
                        <BodyTwoPrimary>({row.SharerRole})</BodyTwoPrimary>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <CaptionPrimary>Recipient</CaptionPrimary>
                      <Tooltip title={row.RecipientEmail}>
                        <Stack direction='row' alignItems='center' gap={0.5}>
                          <BodyOnePrimary>{row.RecipientName}</BodyOnePrimary>
                          <BodyTwoPrimary>({row.RecipientRole})</BodyTwoPrimary>
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <CaptionPrimary>Recipient Merchant</CaptionPrimary>
                      <BodyOnePrimary>{row.RecipientMerchant || '-'}</BodyOnePrimary>
                    </Grid>
                  </Grid>
                </Box>
              ))}
        </Stack>
      </Stack>

      <Pagination
        page={page + 1}
        count={Math.floor(totalCount / pageSize)}
        onChange={onPageChange} />
    </Dashboard >
  );
}
